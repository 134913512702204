import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import Button from '../elements/Button';


const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
  className,
  // topOuterDivider,
  // bottomOuterDivider,
  // topDivider,
  // bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    // topOuterDivider && 'has-top-divider',
    // bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    // topDivider && 'has-top-divider',
    // bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const tagClasses = classNames(
    'tiles-wrap center-content',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: '펌킨 펫하우스 서비스를 소개합니다',
    paragraph: '강아지의 더 좋은 하루를 위한 펌킨의 진심을 만나보세요'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>      
          <SectionHeader data={sectionHeader} className="center-content reveal-from-bottom" />
          <div className={tagClasses}>
{/* 

          <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/feature-tile-icon-01.svg')}
                      alt="Features tile icon 01"
                      width={64}
                      height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    강아지를 기억합니다
                    </h4>
                </div>
              </div>
            </div>


            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/feature-tile-icon-01.svg')}
                      alt="Features tile icon 01"
                      width={64}
                      height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    강아지를 기억합니다
                    </h4>
                </div>
              </div>
            </div>


            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/feature-tile-icon-01.svg')}
                      alt="Features tile icon 01"
                      width={64}
                      height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    강아지를 기억합니다
                    </h4>
                </div>
              </div>
            </div> */}


          </div>
          <div className={splitClasses}>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                PUMPKIN Kindergarten
                  </div>
                <h3 className="mt-0 mb-12">
                  펌킨 유치원
                  </h3>
                <p className="m-0">
                마음이 맞는 친구들과 같은 반이 되어 교육도 받고 체력단련도 할 수 있어요. 
                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/main-kindergarten.png')}
                  alt="Features split 01"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  PUMPKIN Beauty
                  </div>
                <h3 className="mt-0 mb-12">
                  펌킨 미용
                  </h3>
                <p className="m-0">
                섬세한 손길로 강아지의 산뜻한 하루를 디자인합니다.
                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/main-beauty.png')}
                  alt="Features split 02"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  PUMPKIN Hotel
                  </div>
                <h3 className="mt-0 mb-12">
                  펌킨 호텔
                  </h3>
                <p className="m-0">
                낮에는 자유롭게 뛰어놀고, 밤에는 편안하게 잠들어요.
                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/main-hotel.png')}
                  alt="Features split 03"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  PUMPKIN Daily
                  </div>
                <h3 className="mt-0 mb-12">
                  펌킨 데일리
                  </h3>
                <p className="m-0">
                한 시간도 정성스럽게 돌보는 강아지 위탁 프로그램입니다.
                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/main-daily.png')}
                  alt="Features split 02"
                  width={528}
                  height={396} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='center-content reveal-from-bottom'>
      <div className="container-xs">
              <div className="reveal-from-bottom" data-reveal-delay="200">
                <Button tag="a" color="primary" onClick = {() => window.open('https://bit.ly/3HjQxQg','_blank')}>
                펌킨 펫하우스  1:1 상담 
                </Button>
              </div>
              <p className="mt-32 m-0 reveal-from-bottom" data-reveal-delay="400">
              펌킨 펫하우스에 대한 모든 것, 언제나 편하게 문의해 주세요!
                </p>
                {/* <p className="m-0 reveal-from-bottom" data-reveal-delay="400">
                상담원이 다른 상담을 진행 중일 시 바로 답변이 어려울 수 있습니다.
                </p>
                <p className="m-0 reveal-from-bottom" data-reveal-delay="400">
                최대한 빠르게 답변 드리겠습니다.
                </p> */}
            </div>
      </div>
    </section>
  );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;