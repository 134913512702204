import React, { useState } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const PrivacyHero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
            <h2 className="mt-0 mb-16 pb-64 ta-l has-bottom-divider">
              개인정보 취급방침
            </h2>
          </div>
          <div className="hero-figure illustration-element-01" data-reveal-value="20px">
                <div className="text-xxs fw-600 tt-u ta-l mb-8">  
                ㈜ 펌킨컴퍼니(이하 ‘회사’)가 처리하는 모든 개인정보는 관련 법령에 근거하거나 정보주체의 동의에 의하여 수집, 보유 및 처리되고 있습니다. 회사는 이용자들의 개인정보보호를 매우 중요시하며, 회사가 제공하는 홈페이지(모바일 웹/앱 포함)의 회원이 언제나 안심하고 서비스를 이용하실 수 있도록 최선을 다하고 있습니다. 회사는 개인정보보호정책을 통하여 이용자들이 제공하는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며 어떠한 조치를 취하고 있는지 알려드립니다 
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8">  
                회사는 이용자들의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 다음과 같이 개인정보 취급방침을 수립·공개하며, 본 개인정보 취급방침을 홈페이지 첫 화면에 공개함으로써 이용자들이 언제나 용이하게 보실 수 있도록 조치하고 있습니다. 개인정보 취급방침은 정부의 법률 및 지침 변경이나 회사의 내부 방침 변경 등으로 인하여 수시로 변경될 수 있고, 이에 따른 개인정보 취급방침의 지속적인 개선을 위하여 필요한 절차를 정하고 있습니다. 이용자들께서는 사이트 방문 시 수시로 확인하시기 바랍니다. 
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-32">  
                회사의 개인정보 취급방침은 다음과 같은 내용을 담고 있습니다. 
                </div>
                <div className="text-xs fw-600 tt-u ta-l mb-8">  
                제1조 (개인정보 수집 및 이용 목적)  
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8">  
                회사는 아래 사항 외에는, 회원의 기본적 인권 침해의 우려가 있는 민감한 개인정보(인종 및 민족, 사상 및 신조, 출신지 및 본적지, 정치적 성향 및 범죄기록, 건강상태 및 성생활 등)는 수집하지 않으며 부득이하게 수집해야 할 경우 회원의 사전동의를 반드시 구할 것입니다.  
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8">  
                1. 신규 서비스의 개발
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8">  
                회원이 제공한 개인정보를 바탕으로 보다 더 유용한 서비스를 개발할 수 있습니다. 회사는 신규 서비스 개발이나 컨텐츠의 확충 시에 기존 회원이 회사에 제공한 개인정보를 바탕으로 개발해야 할 서비스의 우선 순위를 보다 더 효율적으로 정하고, 회사는 회원이 필요로 할 컨텐츠를 합리적으로 선택하여 제공할 수 있습니다. 
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8">  
                2. 회원 관리
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8">  
                회원제 서비스 이용에 따른 본인확인, 개인 식별, 부정 이용 방지와 비인가 사용 방지, 가입 의사 확인, 연령확인, 주문 및 결제처리, 배송처리 
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8">  
                3. 마케팅 및 광고에 활용 
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8">  
                신규 서비스 개발과 이벤트 행사에 따른 정보 전달 및 맞춤 서비스 제공, 인구통계학적 특성에 따른 서비스 제공 및 광고 게재, 접속 빈도 파악 또는 회원의 서비스 이용에 대한 통계 
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8">  
                ※ 인터넷 서비스 이용과정에서 아래 개인정보 항목이 자동으로 생성되어 수집될 수 있습니다. 
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8">  
                - IP주소, 쿠키, MAC주소, 서비스 이용기록, 방문기록 
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-32">  
                ※ 수집하는 개인정보의 항목은 이용하는 서비스에 따라 달라질 수 있습니다. 
                </div>
                <div className="text-xs fw-600 tt-u ta-l mb-8">  
                제2조 (기본 원칙)  
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8">  
                1. 본 약관에 따르는 거래는 상법 등의 법령과 상관습에 따른 권리와 의무에 입각하여 당사자는 개인정보취급 방침의 내용에 따라 상호이익의 존중 및 신의 성실의 원칙에 따라 이행되어야 한다.  
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8">  
                2. 개인정보 비공개 원칙 
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8">  
                회사는 수집한 개인 정보를 상기 고지한 범위 내에서만 이용하며, 회원의 사전 동의 없이는 동 범위를 초과하여 이용하거나 외부에 공개하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.  
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8">  
                ① 회원이 사전에 개인정보 공개에 동의한 경우 
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-32">  
                ② 법령의 규정에 의거하거나, 법령상 의무를 준수하기 위하여 불가피한 경우 
                </div>
                <div className="text-xs fw-600 tt-u ta-l mb-8">  
                제3조 (개인정보 수집에 대한 동의) 
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-32">  
                회사는 회원이 회사의 개인정보수집이용 동의 또는 서비스 이용약관의 내용에 대하여 「동의」 버튼 또는 「취소」 버튼을 클릭할 수 있는 절차를 마련하여, 「동의」 버튼을 클릭하면 개인정보 수집 등에 대해 동의한 것으로 봅니다. 
                </div>
                <div className="text-xs fw-600 tt-u ta-l mb-8">  
                제4조 (개인정보처리 방침의 효력 및 변경)   
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8">  
                1. 본 개인정보처리방침은 회원이 그 내용에 동의한 경우에 효력이 발생합니다. 
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8">  
                2. 회사는 약관의 규제에 관한 법률, 정보통신망 이용촉진 및 정보보호 등에 관한 법률 등 관계법령에 위배되지 않는 범위 내에서 본 방침을 개정할 수 있습니다. 
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8">  
                3. 회사는 방침을 개정할 경우 현행 방침과 함께 적용일자 30일 전부터 적용일자 및 개정 사유를 명시하여 회원이 사이트에서 확인할 수 있도록 게시하거나 회원이 공개한 연락처로 전자우편을 발송하거나 SMS, 알림 메시지 (Push Notification) 등 회원이 확인할 수 있도록 약관 개정 사실을 통지할 수 있습니다. 다만, 회원이 연락처 미 기재, 변경 후 미 수정 등으로 인하여 개별 통지가 어려운 경우에 한하여 본 항의 공지를 함으로써 개별 통지를 한 것으로 간주합니다. 
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8">  
                4. 개정방침을 공지 또는 통지하였음에도 불구하고 회원이 명시적으로 거부의사를 표시하지 아니하는 경우 회원이 개정방침에 동의한 것으로 봅니다. 
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8">  
                5. 회원은 변경된 방침에 동의하지 아니하는 경우 안타깝지만 서비스를 이용하실 수 없으며, 변경된 방침에 대해서 동의하지 않는 경우 서비스의 이용을 중단하고 이용계약을 해지할 수 있습니다. 
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8">  
                6. 본 방침에 동의하는 것은 서비스를 정기적으로 방문하여 방침의 변경사항을 확인하는 것에 동의함을 의미합니다. 변경된 방침에 대한 정보를 알지 못하여 발생하는 회원의 피해에 대하여 회사는 책임을 지지 않습니다. 
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-32">  
                7. 본 개인정보 취급방침에 대한 추가, 삭제 및 수정이 있을 경우에는 시행하는 날로부터 최소 7일 전에 공지사항 등을 통해 변경 및 내용 등을 공지하도록 하겠습니다. 다만 회원의 소중한 권리 또는 의무에 중요한 내용 변경이 발생하는 경우 시행일로부터 최소 15일 전에 공지하도록 하겠습니다. 
                </div>
                <div className="text-xs fw-600 tt-u ta-l mb-8">  
                제5조 (수집하는 개인정보의 항목 및 수집 방법) 
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8">  
                1. 수집 항목
                </div>
                <table>
                <thead>
                    <tr>
                    <th style={{ textAlign: 'center' }}>구분</th>
                    <th style={{ textAlign: 'center' }}>필수/선택</th>
                    <th style={{ textAlign: 'center' }}>수집 항목</th>
                    <th style={{ textAlign: 'center' }}>목적</th>
                    <th style={{ textAlign: 'center' }}>보유 기간</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                    <td>회원 가입/로그인</td>
                    <td>준회원 (일반회원가입)</td>
                    <td>필수</td>
                    <td>ID, 비밀번호, 닉네임, 이메일(E-mail)</td>
                    <td>이용자 식별, 본인확인, 회원서비스 제공</td>
                    <td>회원 탈퇴 시</td>
                    </tr>

                    <tr>
                    <td>회원 가입/로그인</td>
                    <td>준회원 (간편가입:카카오)</td>
                    <td>필수</td>
                    <td>카카오계정(이메일), 프로필정보(닉네임/프로필 사진), 카카오계정(전화번호), 생일, 연령대, 이름</td>
                    <td>회원 식별, 회원서비스 제공</td>
                    <td>회원 탈퇴 시</td>
                    </tr>

                    <tr>
                    <td>회원 가입/로그인</td>
                    <td>정회원(일반 회원가입 후 본인인증)</td>
                    <td>필수</td>
                    <td>본인인증:성명,생년월일,성별,휴대폰번호,본인인증정보(CI)</td>
                    <td>회원 식별, 회원서비스 제공</td>
                    <td>회원 탈퇴 시</td>
                    </tr>
                    
                    <tr>
                    <td>회원 가입/로그인</td>
                    <td>정회원(일반 회원가입 후 본인인증)</td>
                    <td>선택</td>
                    <td>주소</td>
                    <td>회원 식별, 회원서비스 제공</td>
                    <td>회원 탈퇴 시</td>
                    </tr>

                    <tr>
                    <td>회원 가입/로그인</td>
                    <td>정회원(간편가입:네이버)</td>
                    <td>필수</td>
                    <td>휴대전화번호, 이메일 주소, 별명, 프로필 사진, 성별, 암호화된 동일인 식별정보[CI], 생일, 연령대, 이름</td>
                    <td>회원 식별, 회원서비스 제공</td>
                    <td>회원 탈퇴 시</td>
                    </tr>

                    <tr>
                    <td>회원 가입/로그인</td>
                    <td>SNS를 통한 회원가입 서비스 이용 시 SNS로부터 제공받는 정보
                    (간편가입 대상자: Apple 가입 제외)
                    </td>
                    <td> </td>
                    <td>공통 제공받는 정보: 이메일, 본인확인정보(CI) ※ SNS로부터 제공받은 정보는 회원가입이 진행되지 않는 경우 즉시 파기합니다. 또한, 당시의 회원가입 절차를 통해 고객이 직접 동의한 경우에 한하여 개인정보를 보관합니다.
                    </td>
                    <td> </td>
                    <td> </td>
                    </tr>

                    <tr>
                    <td>등물 등록</td>
                    <td> </td>
                    <td>선택</td>
                    <td>반려동물: 이름, 품종, 성별, 생일, 몸무게, 중성화 여부, 병력, 염려질환, 동거 동물 수, 사진</td>
                    <td>맞춤형 서비스 제공</td>
                    <td>회원 탈퇴 또는 동의 철회시</td>
                    </tr>

                    <tr>
                    <td>부정이용 방지</td>
                    <td> </td>
                    <td>필수</td>
                    <td>ID, 이름, 이메일(E-mail), 휴대폰번호, 본인확인정보(CI)</td>
                    <td>부정이용자 방지</td>
                    <td>회원 탈퇴 후 90 일까지</td>
                    </tr>

                    <tr>
                    <td>마케팅 정보 활용동의</td>
                    <td> </td>
                    <td>선택</td>
                    <td>이름, 휴대폰번호, 이메일(E-mail), 생년월일, 성별, 주소</td>
                    <td>상품 및 서비스 안내</td>
                    <td>회원 탈퇴 또는 동의 철회시</td>
                    </tr>

                    <tr>
                    <td>광고성 수신 동의</td>
                    <td> </td>
                    <td>선택</td>
                    <td>이메일(E-mail), 휴대폰번호(SMS), App Push
                    (app 사용자 전용), 생년월일, 성별, 주소
                    </td>
                    <td>이벤트 정보 및 혜택 제공</td>
                    <td>회원 탈퇴 또는 동의 철회시</td>
                    </tr>

                    <tr>
                    <td>주문/결제/배송</td>
                    <td>결제수단 간편 카드결제</td>
                    <td>필수</td>
                    <td>카드등록: 카드번호, 유효기간, 비밀번호(2자리),
                    간편비밀번호
                    결재정보: 카드번호
                    </td>
                    <td>계약사항 및 주문내역 관리, 
                    상품 배송 및
                    배송지 관리
                    </td>
                    <td>법정 의무 보유 기간 및 이용 목적 달성 시</td>
                    </tr>

                    <tr>
                    <td>주문/결제/배송</td>
                    <td>배송</td>
                    <td>필수</td>
                    <td>배송지 명칭, 
                    받는 사람,
                    휴대폰번호,
                    주소, 상세주소
                    </td>
                    <td>계약사항 및 주문내역 관리, 
                    상품 배송 및
                    배송지 관리
                    </td>
                    <td>법정 의무 보유 기간 및 이용 목적 달성 시</td>
                    </tr>

                    <tr>
                    <td>주문/결제/배송</td>
                    <td>배송</td>
                    <td>선택</td>
                    <td>배송요청사항: 상품 수령위치, 공동현관 출입방법, 택배배송 요청사항
                    </td>
                    <td>계약사항 및 주문내역 관리, 
                    상품 배송 및
                    배송지 관리
                    </td>
                    <td>법정 의무 보유 기간 및 이용 목적 달성 시</td>
                    </tr>

                    <tr>
                    <td>환불</td>
                    <td>주문 취소</td>
                    <td>필수</td>
                    <td>사유</td>
                    <td>결제 환불</td>
                    <td>법정 의무 보유 기간까지</td>
                    </tr>

                    <tr>
                    <td>문의사항</td>
                    <td> </td>
                    <td>필수</td>
                    <td>문의 유형, 문의 내용</td>
                    <td>상품 및 서비스 안내</td>
                    <td>법정 의무 보유 기간 및 이용 목적 달성 시</td>
                    </tr>

                    <tr>
                    <td>문의사항</td>
                    <td> </td>
                    <td>선택</td>
                    <td>이미지(사진), 답변 앱으로 알림 받기</td>
                    <td>상품 및 서비스 안내</td>
                    <td>법정 의무 보유 기간 및 이용 목적 달성 시</td>
                    </tr>

                    <tr>
                    <td>자동으로 수집되는 정보</td>
                    <td>웹 서비스 이용 시 수집될 수 있는 개인정보</td>
                    <td> </td>
                    <td>기기정보(이용자의 브라우저 종류 및 OS), 방문기록(IP, 접속시간), 쿠키정보</td>
                    <td>소비자보호를 위한 법적 의무 준수, 서비스 통계 분석 등</td>
                    <td>회원탈퇴 시 또는 법정 의무 보유기간까지</td>
                    </tr>

                    <tr>
                    <td>자동으로 수집되는 정보</td>
                    <td>모바일기기 이용 시 수집될 수 있는 개인정보(APP)</td>
                    <td> </td>
                    <td>단말기식별정보(IMEI, IMSI),
                    단말기OS정보,
                    광고 식별자(AAID,
                    IDFA), 
                    이동통신회사, 
                    PUSH 수신여부
                    </td>
                    <td>소비자보호를 위한 법적 의무 준수, 서비스 통계 분석 등</td>
                    <td>회원탈퇴 시 또는 법정 의무 보유기간까지</td>
                    </tr>


                </tbody>
                </table>

                <div className="text-xxs fw-600 tt-u ta-l mb-8">  
                2. 수집 방법
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8">  
                회사는 다음과 같은 방법으로 개인정보를 수집합니다. 
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8">  
                - 홈페이지, 어플리케이션, 모바일기기, 서면양식, 팩스, 전화, 상담 게시판, 이메일, 이벤트 응모, 배송요청  
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8">  
                - 협력회사로부터의 제공 
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8">  
                - 생성정보 수집 툴을 통한 수집  
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8">  
                3. 기타   
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-32">  
                어플리케이션 내에서의 설문조사나 이벤트 행사 시 통계분석이나 경품제공 등을 위해 선별적으로 개인정보 입력을 요청할 수 있습니다.  
                </div>
                <div className="text-xs fw-600 tt-u ta-l mb-8">  
                제6조 (수집하는 개인정보의 보유 및 이용기간)  
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8">  
                회사는 회원이 제공한 개인 정보를 서비스 제공 등의 목적범위 내에서만 처리할 수 있으며, 회원이 개인정보의 이용 동의를 철회하거나 개인정보 제공받은 목적이 달성된 경우 재생 불가능한 방법으로 회원의 정보를 지체 없이 파기합니다. 다만, 관련 법령 규정에 의하여 다음과 같이 거래 관련 권리 의무 관계의 확인 등을 이유로 일정기간을 보유하여야 할 필요가 있는 경우 일정기간 보유합니다.  
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8">  
                1. 계약 또는 청약철회 등에 관한 기록: 5년 (전자상거래 등에서의 소비자보호에 관한 법률) 
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8">  
                2. 대금결제 및 재화 등의 공급에 관한 기록: 5년 (전자상거래 등에서의 소비자보호에 관한 법률) 
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8">  
                3. 소비자의 불만 또는 분쟁처리에 관한 기록: 3년 (전자상거래 등에서의 소비자보호에 관한 법률)
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8">  
                4. 방문(로그)에 관한 기록: 3개월(통신비밀보호법) 
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-32">  
                5. 표시, 광고에 관한 기록: 6개월 
                </div>
                <div className="text-xs fw-600 tt-u ta-l mb-8">  
                제7조 (수집한 개인정보의 이용 및 공유 또는 제공)  
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8">  
                회사는 회원의 개인정보를 동의 받은 범위 내에서 사용하며, 회원의 사전 동의 없이는 동 범위를 초과하여 이용하거나 원칙적으로 회원의 개인정보를 외부에 공유 또는 제공하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.  
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8">  
                - 회원이 사전에 이용 및 공유 또는 제공에 동의한 경우 
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8">  
                - 서비스 제공에 따른 요금정산을 위하여 필요한 경우 
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-32">  
                - 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우 
                </div>
                <div className="text-xs fw-600 tt-u ta-l mb-8">  
                제8조 (개인 정보의 제3자 제공)
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8">  
                1. 회사는 당초 수집 목적과 합리적인 범위 내에서 아래 각 기준을 고려하여, 회원의 동의 없이 개인정보를 제3자에게 제공할 수 있습니다.  
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8">  
                가. 당초 수집 목적과 관련성이 있는지 여부: 당초 수집 목적과 추가적 이용•제공 목적이 성질이나 경향에 있어 연관이 있는지 등을 고려하여 따라 판단 
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8">  
                나. 개인정보를 수집한 정황 또는 처리 관행에 비추어 볼 때 개인정보의 추가적인 이용 또는 제공에 대한 예측 가능성이 있는지 여부: 개인정보 처리자와 이용자 간의 관계, 기술 수준 및 발전 속도, 상당한 기간 동안 정립된 일반적인 사정(관행) 등을 고려하여 판단 
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8">  
                다. 이용자의 이익을 부당하게 침해하는지 여부: 추가적인 이용 목적과의 관계에서 이용자의 이익이 실질적으로 침해되는지 및 해당 이익 침해가 부당한지 등을 고려하여 판단 
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8">  
                라. 가명처리 또는 암호화 등 안전성 확보에 필요한 조치를 하였는지 여부: 침해 가능성을 고려한 안전 조치가 취해지는지 등을 고려하여 판단 
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-32">  
                
                </div>
                <div className="text-xs fw-600 tt-u ta-l mb-8">  
                제9조 (개인정보를 자동으로 수집하는 장치의 설치•운영 및 거부에 관한 사항) 
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8">  
                1. 쿠키의 사용 목적 
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8">  
                ① 회사는 개인 맞춤 서비스를 제공하기 위해서 회원에 대한 정보를 저장하고 수시로 불러오는 '쿠키(cookie)'를 사용합니다. 쿠키는 웹사이트 서버가 회원의 브라우저에게 전송하는 소량의 정보로서 이용자 컴퓨터의 하드디스크에 저장됩니다.  
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8">  
                ② 회사는 쿠키의 사용을 통해서만 가능한 특정된 맞춤형 서비스를 제공할 수 있습니다.
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8">  
                ③ 회사는 회원을 식별하고 회원의 로그인 상태를 유지하기 위해 쿠키를 사용할 수 있습니다.
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8">  
                2. 쿠키의 설치/운용 및 거부 
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8">  
                ① 회원은 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서 회원은 웹 브라우저에서 옵션을 조정함으로써 모든 쿠키를 허용/거부하거나, 쿠키가 저장될 때마다 확인을 거치도록 할 수 있습니다. 
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8">  
                - 쿠키 설치 허용 여부를 지정하는 방법(Internet Explorer의 경우)은 다음과 같습니다. 
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8">  
                ºInternet Explorer 웹 브라우저 
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8">  
                [도구] - [인터넷 옵션] - [개인정보] 탭 - [설정] 변경 
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8">  
                ºChrome 웹 브라우저 
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8">  
                우측 상단 메뉴 [설정] - [고급] - [콘텐츠 설정] - [쿠키] 설정 
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8">  
                ºMicrosoft Edge 브라우저 
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8">  
                [설정 및 기타] - 설정 - 개인정보, 검색 및 서비스 - 검색데이터 지우기 - [쿠키 및 사이트 데이터] 선택 후 지우기  
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8">  
                ② 쿠키의 저장을 거부할 경우에는 개인 맞춤서비스 등 회사가 제공하는 일부 서비스는 이용이 어려울 수 있습니다. 
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8">  
                3. 그 이외 자동으로 생성되어 수집되는 정보
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8">  
                웹 서비스: 이용자의 브라우저 종류 및 OS, 방문기록(IP, 접속시간) 
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-32">  
                모바일 기기: 단말기식별정보, 단말기OS정보, 광고 식별자(ADID/IDFA), 이동통신회사, PUSH 수신여부 
                </div>
                <div className="text-xs fw-600 tt-u ta-l mb-8">  
                제10조 (개인정보의 처리위탁)  
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8">  
                회사는 서비스의 원활한 제공을 위해 개인정보를 위탁 처리하고 있으며, 관계 법령에 따라 위탁계약 시 개인정보가 안전하게 관리될 수 있도록 필요한 사항을 계약서 내에 규정하고 있습니다. 현재 회사의 개인정보처리수탁자와 그 업무의 내용은 다음과 같습니다.
                </div>
                <table>
                <thead>
                    <tr>
                    <th style={{ textAlign: 'center' }}>수탁업체</th>
                    <th style={{ textAlign: 'center' }}>수탁업무 내용</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                    <td>AWS</td>
                    <td>서비스 제공을 위한 인프라</td>
                    </tr>
                    <tr>
                    <td>Twilio</td>
                    <td>본인인증</td>
                    </tr>
                    <tr>
                    <td>Firebase</td>
                    <td>서비스 분석을 위한 인프라</td>
                    </tr>
                    <tr>
                    <td>FCM</td>
                    <td>서비스 분석을 위한 인프라</td>
                    </tr>
                    <tr>
                    <td>카카오톡 채널</td>
                    <td>고객상담</td>
                    </tr>
                </tbody>
                </table>
                <div className="text-xxs fw-600 tt-u ta-l mb-8">  
                ※ 업무 특성 상 제공받은 항목 외 고객정보를 추가적으로 수집해야 할 경우는 회원님께 별도의 동의 절차를 거쳐 수집 및 활용합니다. 
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-32">  
                ※ 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체 없이 본 개인정보 처리방침을 통하여 공개하도록 하겠습니다
                </div>
                <div className="text-xs fw-600 tt-u ta-l mb-8">  
                제11조 (개인정보보호를 위한 기술적/관리적 대책) 
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8">  
                1. 회사는 회원의 개인정보를 처리함에 있어 개인정보가 분실, 도난, 유출, 변조, 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 관리적, 기술적, 물리적 대책을 강구하고 있습니다. 
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8">  
                - 관리적 조치 : 내부관리계획 수립. 시행, 정기적 직원 교육 등 
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8">  
                - 기술적 조치 : 개인정보처리시스템 등의 접근권한 관리, 접근통제시스템 설치, 고유식별정보 등의 암호화, 보안프로그램 설치 
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8">  
                - 물리적 조치 : 전산실, 자료보관실 등의 접근통제 
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-32">  
                2. 회사는 회원 개인의 실수나 기본적인 인터넷의 위험성 때문에 일어나는 일들에 대해 책임을 지지 않습니다. 회원 개개인이 본인의 개인정보를 보호하기 위해서 자신의 아이디(ID) 와 비밀번호를 적절하게 관리하고 이에 대한 책임을 져야 합니다. 
                </div>
                <div className="text-xs fw-600 tt-u ta-l mb-8">  
                제12조 (개인정보 관련 의견수렴 및 불만처리에 관한 사항)
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-32">  
                회사는 개인정보보호와 관련하여 회원 여러분들의 의견을 수렴하고 있으며 불만을 처리하기 위하여 모든 절차와 방법을 마련하고 있습니다. 회원은 하단에 명시한 사항을 참고하여 전화나 메일을 통하여 불만사항을 신고할 수 있고, 회사는 회원의 신고사항에 대하여 신속하고도 충분한 답변을 해 드릴 것입니다.  
                </div>
                <div className="text-xs fw-600 tt-u ta-l mb-8">  
                제13조 (개인정보 보호 책임자 등)  
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8">  
                회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 회원의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다. 
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8">  
                이　　  름 　 : 양두환 
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-32">  
                소속 / 직위　: 대표 
                </div>
                <div className="text-xs fw-600 tt-u ta-l mb-8">  
                제14조 (고지의 의무) 
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-32">  
                현 개인정보취급방침의 내용은 정부의 정책 또는 보안기술의 변경에 따라 내용의 추가, 삭제 및 수정이 있을 수 있으며 수정 시에는 홈페이지의 '공지사항'을 통해 고지할 것입니다. 
                </div>
                <div className="text-xs fw-600 tt-u ta-l mb-8">  
                제15조 (이용자 자신의 개인정보 관리에 관한 사항)  
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-32">  
                회원님이 원하실 경우 언제라도 당사에서 개인정보를 열람하실 수 있으며 보관된 필수 정보를 수정하실 수 있습니다. 또한 회원 가입 시 요구된 필수 정보 외의 추가 정보는 언제나 열람, 수정, 삭제할 수 있습니다. 회원님의 개인정보 변경 및 삭제와 회원탈퇴는 당사의 고객센터에서 로그인(Login) 후 이용하실 수 있습니다. 
                </div>
                <div className="text-xs fw-600 tt-u ta-l mb-8">  
                제16조 (이용자 및 법정대리인의 권리와 그 행사방법)  
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8">  
                1. 회사는 법정 대리인의 동의가 필요한 만 14세 미만 아동의 회원가입을 받고 있지 않습니다. 
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8">  
                2. 회원은 언제든지 등록되어 있는 자신의 개인정보를 조회하거나 수정할 수 있으며 가입해지(동의철회)를 요청할 수도 있습니다. 
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8">  
                3. 회원의 개인정보 조회, 수정을 위해서는 로그인 후 My Page에서 ‘개인정보변경’(또는 ‘회원정보수정’ 등)을, 가입해지(동의철회)를 위해서는 "회원탈퇴"를 클릭하여 탈퇴가 가능합니다. 혹은 개인정보 보호책임자에게 서면, 전화 또는 이메일로 연락하시면 지체 없이 조치하겠습니다. 
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8">  
                4. 회원이 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체 없이 통지하여 정정이 이루어지도록 하겠습니다. 
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-32">  
                5. 회사는 회원의 요청에 의해 해지 또는 삭제된 개인정보는 "수집하는 개인정보의 보유 및 이용기간"에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다. 
                </div>
                <div className="text-xs fw-600 tt-u ta-l mb-8">  
                제17조 (개인정보의 파기 절차 및 방법) 
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8">  
                회사는 귀중한 회원의 개인정보를 안전하게 처리하며, 유출의 방지를 위하여 다음과 같은 방법을 통하여 개인정보를 파기합니다.
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8">  
                1. 파기절차 
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8">  
                회원이 서비스 이용 등을 위해 입력하신 정보는 목적 달성 또는 최종 접속일로부터 1년 동안 접속을 하지 않은 대상자를 구별(휴면 회원)하여 별도의 DB로 옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라(보유 및 이용기간 참조) 일정 기간 저장된 후 파기됩니다. 별도 DB로 옮겨진 개인정보는 법률에 의한 경우가 아니면 보유되어지는 이외의 다른 목적으로 이용되지 않습니다. 
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8">  
                ※ 휴면 회원의 경우 별도의 DB로 옮겨지기 전(30일전)에 수집 이용에 동의하여 입력한 정보(SMS 또는 이메일)로 사전 안내를 드립니다.  
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8">  
                2. 파기방법 
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8">  
                - 종이에 출력된 개인정보: 분쇄기로 분쇄하거나 소각 
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-32">  
                - 전자적 파일 형태로 저장된 개인정보: 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제 
                </div>
                <div className="text-xs fw-600 tt-u ta-l mb-8">  
                제18조 (권익침해 시 구제방법)  
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8">  
                회원은 아래의 기관에 대해 개인정보 침해에 대한 피해구제, 상담 등을 문의하실 수 있습니다. 
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8">  
                아래의 기관은 회사와는 별개의 기관으로서, 회사의 자체적인 개인정보 불만처리, 피해구제 결과에 만족하지 못하시거나 보다 자세한 도움이 필요하시면 문의하여 주시기 바랍니다
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8">  
                ▶ 개인정보분쟁조정위원회: (국번없이) 1833-6972 (www.kopico.go.kr) 
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-32">  
                ▶ 개인정보침해신고센터: (국번없이) 118 (privacy.kisa.or.kr) 
                </div>
                <div className="text-xs fw-600 tt-u ta-l mb-8">  
                제19조 (해석 및 분쟁해결)  
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8">  
                1. 본 방침은 방침의 내용에 대해 사회 상규에 위배되지 않는 한 우선적 효력을 인정한다. 
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8">  
                2. 본 방침에 규정되지 않은 사항이나 해석에 이견이 있을 때 서로 합의에 의해 조율하고, 합의가 이루어지지 않을 경우 상관습, 준거법인 대한민국 법령에 의해 해석되고 규율 된다. 
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8">  
                3. 서비스 이용과 관련하여 회사와 회원 사이에 분쟁이 발생한 경우, 회사와 회원은 분쟁의 해결을 위해 성실히 협의합니다. 
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8">  
                4. 본 조 제3항의 협의에서도 분쟁이 해결되지 않을 경우 “회사”의 본사 소재지를 관할하는 법원을 전속 관할법원으로 합니다. 
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8">  
                5. 회사와 회원 간에 제기된 소송에는 대한민국 법을 적용합니다. 
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-32">  
                6. 본 방침은 한글로 작성되었습니다. 본 방침의 번역본이 한글 원문과 차이가 있을 경우 한글 원문이 우선합니다. 
                </div>
                <div className="text-xs fw-600 tt-u ta-l mb-8">  
                부칙
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8">  
                개인정보처리방침 공고일자: 2023.08.30  
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8">  
                개인정보처리방침 시행일자: 2023.08.30 
                </div>
          </div>
        </div>
      </div>
    </section>
  );
}

PrivacyHero.propTypes = propTypes;
PrivacyHero.defaultProps = defaultProps;

export default PrivacyHero;