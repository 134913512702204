import React from 'react';
// import sections
import PrivacyHero from '../components/sections/PrivacyHero';

const Privacy = () => {

    return (
      <>
            <PrivacyHero className/>

      </>
    );
  }
  
  export default Privacy;