import React, { useState } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';
import Image from '../elements/Image';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const FaqHero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const [openMenu, setOpenMenu] = React.useState(1,null);

  const handleMenuClick = (menuId) => {
    if (menuId === openMenu) {
      // setOpenMenu(null);
    } else {
      setOpenMenu(menuId);
    }
  };

  const [visible, setVisible] = useState(null);

  const handleVisibleClick = (visibleId) => {
    if (visibleId === visible) {
      setVisible(null);
    } else {
      setVisible(visibleId);
    }
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
            <h2 className="mt-0 mb-4 ta-l">
              펌킨 펫하우스의 모든 궁금증을
            </h2>
            <h2 className="mt-0 mb-16 pb-64 ta-l has-bottom-divider">
              해결해 드립니다!
            </h2>
            <ButtonGroup>
            <Button className={openMenu===1? "button-primary button-sm" : "button-white button-sm"} onClick={() => handleMenuClick(1)}>
          일반
        </Button>
        <Button className={openMenu===2? "button-primary button-sm" : "button-white button-sm"} onClick={() => handleMenuClick(2)}>
        데일리 & 유치원
        </Button>
        <Button className={openMenu===3? "button-primary button-sm" : "button-white button-sm"} onClick={() => handleMenuClick(3)}>
        미용
        </Button>
        <Button className={openMenu===4? "button-primary button-sm" : "button-white button-sm"} onClick={() => handleMenuClick(4)}>
        호텔
        </Button>
        <Button className={openMenu===5? "button-primary button-sm" : "button-white button-sm"} onClick={() => handleMenuClick(5)}>
          카페
        </Button>
            </ButtonGroup>

            {openMenu === 1 && (
              <div data-reveal-value="20px" data-reveal-delay="600">
                <div className="site-header-inner text-xxs fw-600 tt-u ta-l" onClick={() =>{handleVisibleClick(1)}}>
                Q. 펫하우스 운영시간이 궁금해요!
                <div> {visible===1 ? "숨기기":"보이기"}</div>
                </div>
                  <div className="text-xxs fw-600 tt-u ta-l mb-8"> {visible===1 ? 
                  "A. 펌킨 펫하우스는 연중무휴입니다. 평일 기준 청담점과 서울숲점은 8AM-8PM 영업합니다.":null}
                  </div>
                <div className="site-header-inner text-xxs fw-600 tt-u ta-l has-top-divider" onClick={() =>{handleVisibleClick(2)}}>
                Q. 주차 가능한가요?
                <div> {visible===2 ? "숨기기":"보이기"}</div>
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8"> {visible===2 ? 
                  "A. 청담점 무료주차 가능합니다. 서울숲점 기계식 주차 가능합니다. (30분 2,000원, 추가 10분당 700원, 최대 2만원)":null}
                  </div>     
                <div className="site-header-inner text-xxs fw-600 tt-u ta-l has-top-divider" onClick={() =>{handleVisibleClick(3)}}>
                Q. 대형견도 이용할 수 있나요?
                <div> {visible===3 ? "숨기기":"보이기"}</div>
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8"> {visible===3 ? 
                  "A. 청담점 이용 가능합니다. 서울숲점은 소형견(성견 기준 7kg)만 이용가능합니다. (미용은 중,대형견 이용 가능)":null}
                  </div>
                <div className="site-header-inner text-xxs fw-600 tt-u ta-l has-top-divider" onClick={() =>{handleVisibleClick(4)}}>
                Q. 강아지가 없는데 가도 되나요?
                <div> {visible===4 ? "숨기기":"보이기"}</div>
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8"> {visible===4 ? 
                  "A. 네, 강아지가 없어도 방문 가능합니다.":null}
                  </div>
                <div className="site-header-inner text-xxs fw-600 tt-u ta-l has-top-divider" onClick={() =>{handleVisibleClick(5)}}>
                Q. 모든 강아지가 이용할 수 있나요?
                <div> {visible===5 ? "숨기기":"보이기"}</div>
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8"> {visible===5 ? 
                  "A. 1. 예방접종 미접종 2. 전염성 질환이 있는 경우 3. 심장질환 등 갑작스러운 건강악화가 우려되는 경우 4. 공격성이 심한 경우 5. 맹견8종 및 그 외 유사 견종 6. 발정, 생리 등 특수상황인 경우 입장이 제한됩니다.":null}
                  </div> 
                  <div className="site-header-inner text-xxs fw-600 tt-u ta-l has-top-divider" onClick={() =>{handleVisibleClick(6)}}>
                Q. 흡연 가능한가요?
                <div> {visible===6 ? "숨기기":"보이기"}</div>
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8"> {visible===6 ? 
                  "A. 아니오, 펌킨 펫하우스는 전 구역 금연입니다.":null}
                  </div>   
                  <div className="site-header-inner text-xxs fw-600 tt-u ta-l has-top-divider" onClick={() =>{handleVisibleClick(7)}}>
                Q. 어린이도 갈 수 있나요?
                <div> {visible===7 ? "숨기기":"보이기"}</div>
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8"> {visible===7 ? 
                  "A. 어린이의 안전을 위해 만 13세 이상부터 입장가능합니다.":null}
                  </div> 
                  <div className="site-header-inner text-xxs fw-600 tt-u ta-l has-top-divider" onClick={() =>{handleVisibleClick(8)}}>
                Q. 강아지 분양도 하나요?
                <div> {visible===8 ? "숨기기":"보이기"}</div>
                </div>
                <div className="text-xxs fw-600 tt-u ta-l pb-12 has-bottom-divider"> {visible===8 ? 
                  "A. 아니오, 펌킨 펫하우스는 강아지를 분양하지 않습니다.":null}
                  </div>             
              </div>
          )}
                      {openMenu === 2 && (
              <div data-reveal-value="20px" data-reveal-delay="600">
                <div data-reveal-value="20px" data-reveal-delay="600">
                <div className="site-header-inner text-xxs fw-600 tt-u ta-l" onClick={() =>{handleVisibleClick(11)}}>
                Q. 데일리와 유치원 서비스 운영시간이 궁금해요!
                <div> {visible===11 ? "숨기기":"보이기"}</div>
                </div>
                  <div className="text-xxs fw-600 tt-u ta-l mb-8"> {visible===11 ? 
                  "A. 청담점과 서울숲점  8AM-7PM 입니다. 이후 시간은 데일리 서비스를 이용하실 수 있습니다.":null}
                  </div>
                <div className="site-header-inner text-xxs fw-600 tt-u ta-l has-top-divider" onClick={() =>{handleVisibleClick(12)}}>
                Q. 유치원이랑 데일리, 뭐가 다른가요?
                <div> {visible===12 ? "숨기기":"보이기"}</div>
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8"> {visible===12 ? 
                  "A. 유치원 원아는 반과 담임선생님을 배정받고, 커리큘럼에 따라 교육, 놀이, 식사를 합니다. 보호자님은 사진과 알림장 등을 제공받습니다. 데일리는 친구들과 뛰어놀 수 있는 시간 단위 위탁 서비스입니다.":null}
                  </div>     
                <div className="site-header-inner text-xxs fw-600 tt-u ta-l has-top-divider" onClick={() =>{handleVisibleClick(13)}}>
                Q. 유치원에서 밥도 주나요?
                <div> {visible===13 ? "숨기기":"보이기"}</div>
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8"> {visible===13 ? 
                  "A. 등원 시 원아의 식성과 건강 상태에 맞는 사료를 챙겨 주셔야 합니다. 특별히 원하시는 사료가 없을 시 하우스 사료를 급여합니다.":null}
                  </div>
                <div className="site-header-inner text-xxs fw-600 tt-u ta-l has-top-divider" onClick={() =>{handleVisibleClick(14)}}>
                Q. 우리 강아지는 겁이 많아요.
                <div> {visible===14 ? "숨기기":"보이기"}</div>
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8"> {visible===14 ? 
                  "A. 친구들의 성향에 맞게 반을 분리해서 운영하고 있습니다. 세심한 전학생 코스도 있으니 걱정 마세요!":null}
                  </div>
                <div className="site-header-inner text-xxs fw-600 tt-u ta-l has-top-divider" onClick={() =>{handleVisibleClick(15)}}>
                Q. 우리 강아지는 실외배변만 해요.
                <div> {visible===15 ? "숨기기":"보이기"}</div>
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8"> {visible===15 ? 
                  "A. 실외배변 강아지를 위한 산책 추가 서비스를 운영하고 있습니다.":null}
                  </div> 
                  <div className="site-header-inner text-xxs fw-600 tt-u ta-l has-top-divider" onClick={() =>{handleVisibleClick(16)}}>
                Q. 스쿨버스가 있나요?
                <div> {visible===16 ? "숨기기":"보이기"}</div>
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8"> {visible===16 ? 
                  "A. 네, 픽업 스쿨버스를 운영하고 있습니다.":null}
                  </div>   
                  <div className="site-header-inner text-xxs fw-600 tt-u ta-l has-top-divider" onClick={() =>{handleVisibleClick(17)}}>
                Q. 대형견도 가능한가요?
                <div> {visible===17 ? "숨기기":"보이기"}</div>
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8"> {visible===17 ? 
                  "A. 청담점 등록 가능합니다. 소형견 친구들과 반이 분리될 수 있습니다.":null}
                  </div>
                  <div className="site-header-inner text-xxs fw-600 tt-u ta-l has-top-divider" onClick={() =>{handleVisibleClick(18)}}>
                Q. 산책도 해주시나요?
                <div> {visible===18 ? "숨기기":"보이기"}</div>
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8"> {visible===18 ? 
                  "A. 네, 가능합니다. 아이의 컨디션에 따라 산책시간이 조정 될 수 있습니다.":null}
                  </div> 
                  <div className="site-header-inner text-xxs fw-600 tt-u ta-l has-top-divider" onClick={() =>{handleVisibleClick(19)}}>
                Q. 사진도 보내주나요?
                <div> {visible===19 ? "숨기기":"보이기"}</div>
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8"> {visible===19 ? 
                  "A. 네. 유치원과 호텔 서비스 이용 시 주기적으로 사진을 전송해드립니다.":null}
                  </div> 
                  <div className="site-header-inner text-xxs fw-600 tt-u ta-l has-top-divider" onClick={() =>{handleVisibleClick(20)}}>
                Q. 비용이 궁금해요!
                <div> {visible===20 ? "숨기기":"보이기"}</div>
                </div>
                <div className="text-xxs fw-600 tt-u ta-l pb-12 has-bottom-divider"> {visible===20 ? 
                  (<><Image
                    className="has-shadow"
                    src={require('./../../assets/images/faq-kindergarten-price-1.png')}
                    alt="IoT Hero"
                    width={1080}
                    height={504} />
                    <Image
                    className="has-shadow"
                    src={require('./../../assets/images/faq-daily-price-1.png')}
                    alt="IoT Hero"
                    width={504}
                    height={257} /></>):null}
                  </div>             
              </div>                 
              </div>
          )}
                      {openMenu === 3 && (
              <div data-reveal-value="20px" data-reveal-delay="600">
                <div data-reveal-value="20px" data-reveal-delay="600">
                <div className="site-header-inner text-xxs fw-600 tt-u ta-l" onClick={() =>{handleVisibleClick(21)}}>
                Q. 미용할 때 마취하나요?
                <div> {visible===21 ? "숨기기":"보이기"}</div>
                </div>
                  <div className="text-xxs fw-600 tt-u ta-l mb-8"> {visible===21 ? 
                  "A. 아니오, 마취 미용은 하지 않습니다.":null}
                  </div>
                <div className="site-header-inner text-xxs fw-600 tt-u ta-l has-top-divider" onClick={() =>{handleVisibleClick(22)}}>
                Q. 위생미용에 무엇이 포함되어 있나요?
                <div> {visible===22 ? "숨기기":"보이기"}</div>
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8"> {visible===22 ? 
                  "A. 발바닥 털, 엉덩이 주위, 생식기 주위, 귀청소, 항문낭 압출 그리고 발톱정리입니다.":null}
                  </div>     
                <div className="site-header-inner text-xxs fw-600 tt-u ta-l has-top-divider" onClick={() =>{handleVisibleClick(23)}}>
                Q. 얼마나 오래 걸리나요?
                <div> {visible===23 ? "숨기기":"보이기"}</div>
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8"> {visible===23 ? 
                  "A. 목욕: 1시간-1시간 30분/전체 기계컷: 2시간 30분-3시간/스포팅 3시간-3시간 반/전체 가위컷 3시간 반-4시간, 강아지의 크기와 성향에 따라 추가시간이 발생할 수 있습니다.":null}
                  </div>
                <div className="site-header-inner text-xxs fw-600 tt-u ta-l has-top-divider" onClick={() =>{handleVisibleClick(24)}}>
                Q. 픽업 가능한가요?
                <div> {visible===24 ? "숨기기":"보이기"}</div>
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8"> {visible===24 ? 
                  "A. 근거리 픽업 가능하고, 픽업비가 발생합니다. 예약 시 자세한 상담을 도와드리겠습니다.":null}
                  </div>
                <div className="site-header-inner text-xxs fw-600 tt-u ta-l has-top-divider" onClick={() =>{handleVisibleClick(25)}}>
                Q. 스파 등 특수 케어도 있나요?
                <div> {visible===25 ? "숨기기":"보이기"}</div>
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8"> {visible===25 ? 
                  "A. 네, 탄산 스파와 머드 케어가 있습니다.":null}
                  </div> 
                  <div className="site-header-inner text-xxs fw-600 tt-u ta-l has-top-divider" onClick={() =>{handleVisibleClick(26)}}>
                Q. 입질 있는 강아지도 미용이 되나요?
                <div> {visible===26 ? "숨기기":"보이기"}</div>
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8"> {visible===26 ? 
                  "A. 네, 가능합니다. 다만 입질이 지나칠 경우 강아지와 미용선생님의 안전을 위해 진행하지 않습니다.":null}
                  </div>   
                  <div className="site-header-inner text-xxs fw-600 tt-u ta-l has-top-divider" onClick={() =>{handleVisibleClick(27)}}>
                Q. 양치도 해주시나요?
                <div> {visible===27 ? "숨기기":"보이기"}</div>
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8"> {visible===27 ? 
                  "A. 네. 양치를 진행합니다. 5,000원의 유상서비스입니다.":null}
                  </div>
                  <div className="site-header-inner text-xxs fw-600 tt-u ta-l has-top-divider" onClick={() =>{handleVisibleClick(28)}}>
                Q. 린스도 해주시나요?
                <div> {visible===28 ? "숨기기":"보이기"}</div>
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8"> {visible===28 ? 
                  "A. 네, 목욕에 린스가 포함되어 있습니다. 피부질환이 있거나, 예민성 피부의 경우 생략될 수 있습니다.":null}
                  </div> 
                  <div className="site-header-inner text-xxs fw-600 tt-u ta-l has-top-divider" onClick={() =>{handleVisibleClick(29)}}>
                Q. 피부병이 있는데 이용이 가능할까요?
                <div> {visible===29 ? "숨기기":"보이기"}</div>
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8"> {visible===29 ? 
                  "A. 전염성이 있는 질환이라면 입장이 제한됩니다. 그렇지 않다면 목욕, 약욕 모두 가능합니다.":null}
                  </div> 
                  <div className="site-header-inner text-xxs fw-600 tt-u ta-l has-top-divider" onClick={() =>{handleVisibleClick(30)}}>
                Q. 목욕 및 미용 비용은 얼마인가요?
                <div> {visible===30 ? "숨기기":"보이기"}</div>
                </div>
                <div className="text-xxs fw-600 tt-u ta-l pb-12 has-bottom-divider"> {visible===30 ? 
                  (<><Image
                    className="has-shadow"
                    src={require('./../../assets/images/faq-beauty-price-1.png')}
                    alt="IoT Hero"
                    width={1080}
                    height={504} />
                    <Image
                    className="has-shadow"
                    src={require('./../../assets/images/faq-beauty-price-2.png')}
                    alt="IoT Hero"
                    width={1080}
                    height={504} /></>):null}
                  </div>             
              </div>                 
              </div>
          )}
                      {openMenu === 4 && (
              <div data-reveal-value="20px" data-reveal-delay="600">
                <div data-reveal-value="20px" data-reveal-delay="600">
                <div className="site-header-inner text-xxs fw-600 tt-u ta-l" onClick={() =>{handleVisibleClick(31)}}>
                Q. 체크인 체크아웃 시간이 궁금해요!
                <div> {visible===31 ? "숨기기":"보이기"}</div>
                </div>
                  <div className="text-xxs fw-600 tt-u ta-l mb-8"> {visible===31 ? 
                  "A. 영업시간 내에 언제든 오시면 되고, 7PM 이후 체크인, 10AM 이후 체크아웃 입니다. ":null}
                  </div>
                <div className="site-header-inner text-xxs fw-600 tt-u ta-l has-top-divider" onClick={() =>{handleVisibleClick(32)}}>
                Q. 비용이 궁금해요!
                <div> {visible===32 ? "숨기기":"보이기"}</div>
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8"> {visible===32 ? 
                  (<Image
                    className="has-shadow"
                    src={require('./../../assets/images/faq-hotel-price-1.png')}
                    alt="IoT Hero"
                    width={504}
                    height={257} />):null}
                  </div>     
                <div className="site-header-inner text-xxs fw-600 tt-u ta-l has-top-divider" onClick={() =>{handleVisibleClick(33)}}>
                Q. 24시간 호텔장 안에 있나요?
                <div> {visible===33 ? "숨기기":"보이기"}</div>
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8"> {visible===33 ? 
                  "A. 낮에는 친구들과 어울리고 뛰어놀 수 있고, 밤에는 안전한 개별 룸에서 잠들어요. 보호자님의 요청에 따라 분리 여부를 결정합니다.":null}
                  </div>
                <div className="site-header-inner text-xxs fw-600 tt-u ta-l has-top-divider" onClick={() =>{handleVisibleClick(34)}}>
                Q. 사진과 연락 주시나요
                <div> {visible===34 ? "숨기기":"보이기"}</div>
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8"> {visible===34 ? 
                  "A. 네, 입 · 퇴실 때와 식사시간, 놀이시간 등에 사진, 동영상을 전송해 드립니다.":null}
                  </div>
                <div className="site-header-inner text-xxs fw-600 tt-u ta-l has-top-divider" onClick={() =>{handleVisibleClick(35)}}>
                Q. 식사시간은 언제인가요?
                <div> {visible===35 ? "숨기기":"보이기"}</div>
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8"> {visible===35 ? 
                  "A. 오전 10시, 오후 5시에 식사를 급여합니다. 원하시면 점심 급여도 가능합니다. 저녁을 못 먹은 친구는 입실 시 식사를 제공합니다.":null}
                  </div> 
                  <div className="site-header-inner text-xxs fw-600 tt-u ta-l has-top-divider" onClick={() =>{handleVisibleClick(36)}}>
                Q. 간식도 주시나요?
                <div> {visible===36 ? "숨기기":"보이기"}</div>
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8"> {visible===36 ? 
                  "A. 보호자님께서 챙겨주실 경우 급여 가능합니다.":null}
                  </div>   
                  <div className="site-header-inner text-xxs fw-600 tt-u ta-l has-top-divider" onClick={() =>{handleVisibleClick(37)}}>
                Q. 다른 친구들과 안 만나면 좋겠어요.
                <div> {visible===37 ? "숨기기":"보이기"}</div>
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8"> {visible===37 ? 
                  "A. 단독 분리 케어가 가능합니다.":null}
                  </div> 
                  <div className="site-header-inner text-xxs fw-600 tt-u ta-l has-top-divider" onClick={() =>{handleVisibleClick(38)}}>
                Q. 산책도 시켜 주시나요?
                <div> {visible===38 ? "숨기기":"보이기"}</div>
                </div>
                <div className="text-xxs fw-600 tt-u ta-l pb-12 has-bottom-divider"> {visible===38 ? 
                  "A. 네, 가능합니다.  2만원이 발생되며, 아이의 컨디션에 따라 산책시간이 조정 될 수 있습니다.":null}
                  </div>             
              </div>                 
              </div>
          )}
                      {openMenu === 5 && (
              <div data-reveal-value="20px" data-reveal-delay="600">
                <div data-reveal-value="20px" data-reveal-delay="600">
                <div className="site-header-inner text-xxs fw-600 tt-u ta-l" onClick={() =>{handleVisibleClick(41)}}>
                Q. 카페 이용시간이 궁금해요!
                <div> {visible===41 ? "숨기기":"보이기"}</div>
                </div>
                  <div className="text-xxs fw-600 tt-u ta-l mb-8"> {visible===41 ? 
                  "A. 1인 1메뉴 주문 시 2시간입니다. 2시간 초과 시 추가 주문을 부탁드립니다.":null}
                  </div>
                <div className="site-header-inner text-xxs fw-600 tt-u ta-l has-top-divider" onClick={() =>{handleVisibleClick(42)}}>
                Q. 강아지 입장료가 있나요?
                <div> {visible===42 ? "숨기기":"보이기"}</div>
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8"> {visible===42 ? 
                  "A. 강아지 입장료 무료입니다.":null}
                  </div>     
                <div className="site-header-inner text-xxs fw-600 tt-u ta-l has-top-divider" onClick={() =>{handleVisibleClick(43)}}>
                Q. 외부음식 들고 가도 될까요?
                <div> {visible===43 ? "숨기기":"보이기"}</div>
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8"> {visible===43 ? 
                  "A. 강아지들의 음식 사고 방지를 위해 외부 음식 반입을 제한합니다.":null}
                  </div>
                <div className="site-header-inner text-xxs fw-600 tt-u ta-l has-top-divider" onClick={() =>{handleVisibleClick(44)}}>
                Q. 강아지가 없는데 가도 되나요?
                <div> {visible===44 ? "숨기기":"보이기"}</div>
                </div>
                <div className="text-xxs fw-600 tt-u ta-l mb-8"> {visible===44 ? 
                  "A. 네, 강아지가 없어도 환영합니다.":null}
                  </div>
                  <div className="site-header-inner text-xxs fw-600 tt-u ta-l has-top-divider" onClick={() =>{handleVisibleClick(45)}}>
                Q. 목줄 풀어놓아도 되나요?
                <div> {visible===45 ? "숨기기":"보이기"}</div>
                </div>
                <div className="text-xxs fw-600 tt-u ta-l pb-12 has-bottom-divider"> {visible===45 ? 
                  "A. 네, 오프리쉬 가능하지만 필요시 직원이 착용을 안내할 수 있습니다.":null}
                  </div>             
              </div>                
              </div>
          )}
            
          </div>
        </div>
      </div>
    </section>
  );
}

FaqHero.propTypes = propTypes;
FaqHero.defaultProps = defaultProps;

export default FaqHero;