import React, { useState } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';
import Image from '../elements/Image';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const ServiceHero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const [openMenu, setOpenMenu] = React.useState(1,null);

  const handleMenuClick = (menuId) => {
    if (menuId === openMenu) {
      // setOpenMenu(null);
    } else {
      setOpenMenu(menuId);
    }
  };

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
            <h2 className="mt-0 mb-4 ta-l">
              아이들의 행복이 넘치는
            </h2>
            <h2 className="mt-0 mb-16 pb-64 ta-l has-bottom-divider">
              펌킨 펫하우스
            </h2>
          </div>
          <ButtonGroup>
        <Button className={openMenu===1? "button-primary button-sm" : "button-white button-sm"} onClick={() => handleMenuClick(1)}>
          KINDERGARTEN
        </Button>
        <Button className={openMenu===2? "button-primary button-sm" : "button-white button-sm"} onClick={() => handleMenuClick(2)}>
        BEAUTY
        </Button>
        <Button className={openMenu===3? "button-primary button-sm" : "button-white button-sm"} onClick={() => handleMenuClick(3)}>
        HOTEL
        </Button>
        <Button className={openMenu===4? "button-primary button-sm" : "button-white button-sm"} onClick={() => handleMenuClick(4)}>
        DAILY
        </Button>
        <Button className={openMenu===5? "button-primary button-sm" : "button-white button-sm"} onClick={() => handleMenuClick(5)}>
          CAFE
        </Button>
        {/* <Button className="reveal-from-bottom button-primary button-sm" onClick={() => handleMenuClick(6)}>
          MEDICAL CENTER
        </Button> */}
      </ButtonGroup>

      {openMenu === 1 && (
          <div data-reveal-value="20px" data-reveal-delay="600">
                <Slider classname="mb-32" {...settings}>
                  <div>
                    <h3>          
                      <Image
                        className="has-shadow"
                        src={require('./../../assets/images/service-kindergarten-1.png')}
                        alt="IoT Hero"
                        width={1080}
                        height={504} /></h3>
                  </div>
                  {/* <div>
                    <h3>
                      <Image
                        className="has-shadow"
                        src={require('./../../assets/images/iot-hero-image.png')}
                        alt="IoT Hero"
                        width={1080}
                        height={504} />
                    </h3>
                  </div>

                  <div>
                    <h3>
                    <Image
                        className="has-shadow"
                        src={require('./../../assets/images/iot-hero-image.png')}
                        alt="IoT Hero"
                        width={1080}
                        height={504} />
                    </h3>
                  </div>
                  <div>
                    <h3>
                    <Image
                        className="has-shadow"
                        src={require('./../../assets/images/iot-hero-image.png')}
                        alt="IoT Hero"
                        width={1080}
                        height={504} />
                    </h3>
                  </div>
                  <div>
                    <h3>
                    <Image
                        className="has-shadow"
                        src={require('./../../assets/images/iot-hero-image.png')}
                        alt="IoT Hero"
                        width={1080}
                        height={504} />
                    </h3>
                  </div>
                  <div>
                    <h3>
                    <Image
                        className="has-shadow"
                        src={require('./../../assets/images/iot-hero-image.png')}
                        alt="IoT Hero"
                        width={1080}
                        height={504} />
                    </h3>
                  </div> */}
                </Slider>
          </div>
      )}
      {openMenu === 1 && (
          <div data-reveal-value="20px" data-reveal-delay="600">
            <h3 className="mt-12 mb-12 ta-l">
              신나는 유치원
            </h3>
            <p className="mt-0 mb-12 ta-l text-xxs fw-600">
              강아지도 유치원과 친구들이 필요해요!
            </p>
            <div className="text-xxs fw-600 mb-8 pb-16 ta-l">
            마음이 맞는 친구들과 같은 반이 되어 교육도 받고 체력도 길러요.
            </div>
            <div className="text-xxs fw-600 tt-u pt-16 pb-16 ta-l has-top-divider">
            훈련 선생님의 스쿨버스 픽업
            </div>
            <div className="text-xxs fw-600 tt-u pt-16 pb-16 ta-l has-top-divider">
            예절 교육과 사회화, 어질리티 훈련
            </div>     
            <div className="text-xxs fw-600 tt-u pt-16 pb-16 ta-l has-top-divider">
            담임 선생님의 꼼꼼한 생활기록 전달
            </div>
            <div className="text-xxs fw-600 tt-u pt-16 pb-16 ta-l has-top-divider">
            운영 시간 : 오전 8시부터 오후 7시까지
            </div>
            <div className="text-xxs fw-600 tt-u pt-16 pb-16 ta-l has-top-divider">
            {/* &lt;1:1 상담&gt; */}
            </div>
            <Button tag="a" color="primary" onClick = {() => window.open('https://bit.ly/3HjQxQg','_blank')}>
                  1:1 상담
                </Button>                
          </div>
      )}

      {openMenu === 2 && (
          <div data-reveal-value="20px" data-reveal-delay="600">
                <Slider classname="mb-32" {...settings}>
                  <div>
                    <h3>          
                      <Image
                        className="has-shadow"
                        src={require('./../../assets/images/service-beauty-1.png')}
                        alt="IoT Hero"
                        width={1080}
                        height={504} /></h3>
                  </div>
                  <div>
                    <h3>
                      <Image
                        className="has-shadow"
                        src={require('./../../assets/images/service-beauty-2.png')}
                        alt="IoT Hero"
                        width={1080}
                        height={504} />
                    </h3>
                  </div>

                  {/* <div>
                    <h3>
                    <Image
                        className="has-shadow"
                        src={require('./../../assets/images/iot-hero-image.png')}
                        alt="IoT Hero"
                        width={1080}
                        height={504} />
                    </h3>
                  </div>
                  <div>
                    <h3>
                    <Image
                        className="has-shadow"
                        src={require('./../../assets/images/iot-hero-image.png')}
                        alt="IoT Hero"
                        width={1080}
                        height={504} />
                    </h3>
                  </div>
                  <div>
                    <h3>
                    <Image
                        className="has-shadow"
                        src={require('./../../assets/images/iot-hero-image.png')}
                        alt="IoT Hero"
                        width={1080}
                        height={504} />
                    </h3>
                  </div>
                  <div>
                    <h3>
                    <Image
                        className="has-shadow"
                        src={require('./../../assets/images/iot-hero-image.png')}
                        alt="IoT Hero"
                        width={1080}
                        height={504} />
                    </h3>
                  </div> */}
                </Slider>
          </div>
      )}
      {openMenu === 2 && (
          <div data-reveal-value="20px" data-reveal-delay="600">
            <h3 className="mt-12 mb-12 ta-l">
            섬세한 미용
            </h3>
            <p className="mt-0 mb-12 ta-l text-xxs fw-600">
              강아지의 마음을 읽으며 미용합니다.
            </p>
            <div className="text-xxs fw-600 mb-8 pb-16 ta-l">
            마음이 맞는 친구들과 같은 반이 되어 교육도 받고 체력도 길러요.
            </div>
            <div className="text-xxs fw-600 tt-u pt-16 pb-16 ta-l has-top-divider">
            미용 당일 데일리 2시간 무료제공
            </div>
            <div className="text-xxs fw-600 tt-u pt-16 pb-16 ta-l has-top-divider">
            마음을 녹이는 아로마 스파와 머드 마사지
            </div>
            <div className="text-xxs fw-600 tt-u pt-16 pb-16 ta-l has-top-divider">
            미용 선생님의 꼼꼼한 성향 기록과 전후사진 전달
            </div>     
            <div className="text-xxs fw-600 tt-u pt-16 pb-16 ta-l has-top-divider">
            운영 시간 : 오전 10시부터 오후 7시까지
            </div>
            <div className="text-xxs fw-600 tt-u pt-16 pb-16 ta-l has-top-divider">
            {/* &lt;1:1 상담&gt; */}
            </div>
            <Button tag="a" color="primary" onClick = {() => window.open('https://bit.ly/3HjQxQg','_blank')}>
                  1:1 상담
                </Button>  
        </div>
      )}

      {openMenu === 3 && (
          <div data-reveal-value="20px" data-reveal-delay="600">
                <Slider classname="mb-32" {...settings}>
                  <div>
                    <h3>          
                      <Image
                        className="has-shadow"
                        src={require('./../../assets/images/service-hotel-1.png')}
                        alt="IoT Hero"
                        width={1080}
                        height={504} /></h3>
                  </div>
                  <div>
                    <h3>
                      <Image
                        className="has-shadow"
                        src={require('./../../assets/images/service-hotel-2.png')}
                        alt="IoT Hero"
                        width={1080}
                        height={504} />
                    </h3>
                  </div>
{/* 
                  <div>
                    <h3>
                    <Image
                        className="has-shadow"
                        src={require('./../../assets/images/iot-hero-image.png')}
                        alt="IoT Hero"
                        width={1080}
                        height={504} />
                    </h3>
                  </div>
                  <div>
                    <h3>
                    <Image
                        className="has-shadow"
                        src={require('./../../assets/images/iot-hero-image.png')}
                        alt="IoT Hero"
                        width={1080}
                        height={504} />
                    </h3>
                  </div>
                  <div>
                    <h3>
                    <Image
                        className="has-shadow"
                        src={require('./../../assets/images/iot-hero-image.png')}
                        alt="IoT Hero"
                        width={1080}
                        height={504} />
                    </h3>
                  </div>
                  <div>
                    <h3>
                    <Image
                        className="has-shadow"
                        src={require('./../../assets/images/iot-hero-image.png')}
                        alt="IoT Hero"
                        width={1080}
                        height={504} />
                    </h3>
                  </div> */}
                </Slider>
          </div>
      )}
      {openMenu === 3 && (
          <div data-reveal-value="20px" data-reveal-delay="600">
            <h3 className="mt-12 mb-12 ta-l">
              편안한 호텔
            </h3>
            <p className="mt-0 mb-12 ta-l text-xxs fw-600">
              강아지의 마음을 읽으며 미용합니다.
            </p>
            <div className="text-xxs fw-600 mb-8 pb-16 ta-l">
            강아지 위탁 그 이상의 소중한 추억을 선물합니다.
            </div>
            <div className="text-xxs fw-600 tt-u pt-16 pb-16 ta-l has-top-divider">
            낮에는 친구들과 자유롭게 뛰어놀기
            </div>
            <div className="text-xxs fw-600 tt-u pt-16 pb-16 ta-l has-top-divider">
            미용, 산책, 양치 서비스 추가 가능
            </div>
            <div className="text-xxs fw-600 tt-u pt-16 pb-16 ta-l has-top-divider">
            담당 선생님의 일상 기록 실시간 전달
            </div>     
            <div className="text-xxs fw-600 tt-u pt-16 pb-16 ta-l has-top-divider">
            체크인 오후 7시 전후, 체크아웃 오전 10시 전후
            </div>
            <div className="text-xxs fw-600 tt-u pt-16 pb-16 ta-l has-top-divider">
            {/* &lt;1:1 상담&gt; */}
            </div>
            <Button tag="a" color="primary" onClick = {() => window.open('https://bit.ly/3HjQxQg','_blank')}>
                  1:1 상담
                </Button> 
        </div>
      )}

      {openMenu === 4 && (
          <div data-reveal-value="20px" data-reveal-delay="600">
                <Slider classname="mb-32" {...settings}>
                  <div>
                    <h3>          
                      <Image
                        className="has-shadow"
                        src={require('./../../assets/images/service-daily-1.png')}
                        alt="IoT Hero"
                        width={1080}
                        height={504} /></h3>
                  </div>
                  {/* <div>
                    <h3>
                      <Image
                        className="has-shadow"
                        src={require('./../../assets/images/iot-hero-image.png')}
                        alt="IoT Hero"
                        width={1080}
                        height={504} />
                    </h3>
                  </div>

                  <div>
                    <h3>
                    <Image
                        className="has-shadow"
                        src={require('./../../assets/images/iot-hero-image.png')}
                        alt="IoT Hero"
                        width={1080}
                        height={504} />
                    </h3>
                  </div>
                  <div>
                    <h3>
                    <Image
                        className="has-shadow"
                        src={require('./../../assets/images/iot-hero-image.png')}
                        alt="IoT Hero"
                        width={1080}
                        height={504} />
                    </h3>
                  </div>
                  <div>
                    <h3>
                    <Image
                        className="has-shadow"
                        src={require('./../../assets/images/iot-hero-image.png')}
                        alt="IoT Hero"
                        width={1080}
                        height={504} />
                    </h3>
                  </div>
                  <div>
                    <h3>
                    <Image
                        className="has-shadow"
                        src={require('./../../assets/images/iot-hero-image.png')}
                        alt="IoT Hero"
                        width={1080}
                        height={504} />
                    </h3>
                  </div> */}
                </Slider>
          </div>
      )}
      {openMenu === 4 && (
          <div data-reveal-value="20px" data-reveal-delay="600">
            <h3 className="mt-12 mb-12 ta-l">
              설레는 데일리
            </h3>
            <p className="mt-0 mb-12 ta-l text-xxs fw-600">
              한 시간도 즐겁고 안전하게
            </p>
            <div className="text-xxs fw-600 mb-8 pb-16 ta-l">
            훈련 선생님과 함께하는 1시간 단위 펫시팅
            </div>
            <div className="text-xxs fw-600 tt-u pt-16 pb-16 ta-l has-top-divider">
            미용, 산책, 양치 서비스 추가 가능
            </div>
            <div className="text-xxs fw-600 tt-u pt-16 pb-16 ta-l has-top-divider">
            유치원 장난감 모두 이용 가능
            </div>
            <div className="text-xxs fw-600 tt-u pt-16 pb-16 ta-l has-top-divider">
            요청 시 단독 룸 제공 가능
            </div>
            <div className="text-xxs fw-600 tt-u pt-16 pb-16 ta-l has-top-divider">
            {/* &lt;1:1 상담&gt; */}
            </div>     
            <Button tag="a" color="primary" onClick = {() => window.open('https://bit.ly/3HjQxQg','_blank')}>
                  1:1 상담
                </Button>            
        </div>
      )}

      {openMenu === 5 && (
          <div data-reveal-value="20px" data-reveal-delay="600">
                <Slider classname="mb-32" {...settings}>
                  <div>
                    <h3>          
                      <Image
                        className="has-shadow"
                        src={require('./../../assets/images/service-cafe-1.png')}
                        alt="IoT Hero"
                        width={1080}
                        height={504} /></h3>
                  </div>
                  <div>
                    <h3>
                      <Image
                        className="has-shadow"
                        src={require('./../../assets/images/service-cafe-2.png')}
                        alt="IoT Hero"
                        width={1080}
                        height={504} />
                    </h3>
                  </div>
{/* 
                  <div>
                    <h3>
                    <Image
                        className="has-shadow"
                        src={require('./../../assets/images/iot-hero-image.png')}
                        alt="IoT Hero"
                        width={1080}
                        height={504} />
                    </h3>
                  </div>
                  <div>
                    <h3>
                    <Image
                        className="has-shadow"
                        src={require('./../../assets/images/iot-hero-image.png')}
                        alt="IoT Hero"
                        width={1080}
                        height={504} />
                    </h3>
                  </div>
                  <div>
                    <h3>
                    <Image
                        className="has-shadow"
                        src={require('./../../assets/images/iot-hero-image.png')}
                        alt="IoT Hero"
                        width={1080}
                        height={504} />
                    </h3>
                  </div>
                  <div>
                    <h3>
                    <Image
                        className="has-shadow"
                        src={require('./../../assets/images/iot-hero-image.png')}
                        alt="IoT Hero"
                        width={1080}
                        height={504} />
                    </h3>
                  </div> */}
                </Slider>
          </div>
      )}
      {openMenu === 5 && (
          <div data-reveal-value="20px" data-reveal-delay="600">
            <h3 className="mt-12 mb-12 ta-l">
              향긋한 카페
            </h3>
            <p className="mt-0 mb-12 ta-l text-xxs fw-600">
              강아지와 함께하는 커피 한잔
            </p>
            <div className="text-xxs fw-600 mb-8 pb-16 ta-l">
            뛰어노는 강아지를 바라보며 여유로운 하루를 즐겨보세요
            </div>
            <div className="text-xxs fw-600 tt-u pt-16 pb-16 ta-l has-top-divider">
            강아지 입장료 무료
            </div>
            <div className="text-xxs fw-600 tt-u pt-16 pb-16 ta-l has-top-divider">
            강아지 오프리쉬 가능
            </div>
            <div className="text-xxs fw-600 tt-u pt-16 pb-16 ta-l has-top-divider">
            다양한 카페 메뉴
            </div>
            <div className="text-xxs fw-600 tt-u pt-16 pb-16 ta-l has-top-divider">
            {/* &lt;1:1 상담&gt; */}
            </div>     
            <Button tag="a" color="primary" onClick = {() => window.open('https://bit.ly/3HjQxQg','_blank')}>
                  1:1 상담
                </Button>            
        </div>
      )}

      {/* {openMenu === 6 && (
          <div data-reveal-value="20px" data-reveal-delay="600">
                <Slider classname="mb-32" {...settings}>
                  <div>
                    <h3>          
                      <Image
                        className="has-shadow"
                        src={require('./../../assets/images/iot-hero-image.png')}
                        alt="IoT Hero"
                        width={1080}
                        height={504} /></h3>
                  </div>
                  <div>
                    <h3>
                      <Image
                        className="has-shadow"
                        src={require('./../../assets/images/iot-hero-image.png')}
                        alt="IoT Hero"
                        width={1080}
                        height={504} />
                    </h3>
                  </div>

                  <div>
                    <h3>
                    <Image
                        className="has-shadow"
                        src={require('./../../assets/images/iot-hero-image.png')}
                        alt="IoT Hero"
                        width={1080}
                        height={504} />
                    </h3>
                  </div>
                  <div>
                    <h3>
                    <Image
                        className="has-shadow"
                        src={require('./../../assets/images/iot-hero-image.png')}
                        alt="IoT Hero"
                        width={1080}
                        height={504} />
                    </h3>
                  </div>
                  <div>
                    <h3>
                    <Image
                        className="has-shadow"
                        src={require('./../../assets/images/iot-hero-image.png')}
                        alt="IoT Hero"
                        width={1080}
                        height={504} />
                    </h3>
                  </div>
                  <div>
                    <h3>
                    <Image
                        className="has-shadow"
                        src={require('./../../assets/images/iot-hero-image.png')}
                        alt="IoT Hero"
                        width={1080}
                        height={504} />
                    </h3>
                  </div>
                </Slider>
          </div>
      )}
      {openMenu === 6 && (
          <div data-reveal-value="20px" data-reveal-delay="600">
            <h3 className="mt-0 mb-12 ta-l">
              신나는 병원
            </h3>
            <div className="text-xxs fw-600 tt-u mb-8 pb-16 ta-l">
            반려견의 또다른 하우스가 반려동물과 보호자, 반려가족 모두를 생각합니다. 반려의 마음이 깃든 건강한 라이프스타일을 제안합니다. 반려견의 또다른 하우스가 반려동물과 보호자, 반려가족 모두를 생각합니다. 반려의 마음이 깃든 건강한 라이프스타일을 제안합니다.
            </div>
            <div className="text-xxs fw-600 tt-u pt-16 pb-16 ta-l has-top-divider">
            훌륭한 훈련사 선생님과 함께 해요!
            </div>
            <div className="text-xxs fw-600 tt-u pt-16 pb-16 ta-l has-top-divider">
            다양한 어질리티 도구를 활용한 훈련을 할 수 있어요!
            </div>     
            <div className="text-xxs fw-600 tt-u pt-16 pb-16 ta-l has-top-divider">
            안전하게 유치원으로 픽업해 드려요!
            </div>
            <div className="text-xxs fw-600 tt-u pt-16 pb-16 ta-l has-top-divider">
            운영 시간 : 오전 9시부터 오후 7시까지
            </div>            
        </div>
      )} */}

        </div>
      </div>
    </section>
  );
}

ServiceHero.propTypes = propTypes;
ServiceHero.defaultProps = defaultProps;

export default ServiceHero;