import React from 'react';
// import sections
import IoTHero from '../components/sections/IoTHero';

const IoT = () => {

    return (
      <>
            <IoTHero className/>

      </>
    );
  }
  
  export default IoT;