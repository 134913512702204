import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Input from '../elements/Input';
import { CountUp } from 'use-count-up';

const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool
}

const defaultProps = {
  ...SectionProps.defaults,
  split: false
}

const Cta = ({
  className,
  // topOuterDivider,
  // bottomOuterDivider,
  // topDivider,
  // bottomDivider,
  hasBgColor,
  invertColor,
  split,
  ...props
}) => {

  const outerClasses = classNames(
    'cta section center-content-mobile reveal-from-bottom',
    // topOuterDivider && 'has-top-divider',
    // bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'cta-inner section-inner',
    // topDivider && 'has-top-divider',
    // bottomDivider && 'has-bottom-divider',
    split && 'cta-split',
  );  

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div
          className={innerClasses}
          
        >          
            <div className=" reveal-from-left" data-reveal-delay="200">
              <h5 className="mt-0 mb-8 ta-c">
                펌킨 펫하우스 지점수
                </h5>
                <h2 className="m-0 ta-c">
                  <CountUp isCounting end={2} duration={4} />
                </h2>             
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <h5 className="mt-0 mb-8 ta-c">
                펌킨 펫하우스 원아수
                </h5>
                <h2 className="m-0 ta-c">
                  <CountUp isCounting end={2046} duration={8} />
                </h2>  
            </div>

            <div className="tiles-item reveal-from-right" data-reveal-delay="600">
              <h5 className="mt-0 mb-8 ta-c">
                펌킨 선생님 수
                </h5>
                <h2 className="m-0 ta-c">
                  <CountUp isCounting end={12} duration={9} />
                </h2>  
            </div>
          </div>
      </div>
      
    </section>
  );
}

Cta.propTypes = propTypes;
Cta.defaultProps = defaultProps;

export default Cta;