import React, { useState } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';
import Image from '../elements/Image';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const ContactHero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const [openMenu, setOpenMenu] = React.useState(1,null);

  const handleMenuClick = (menuId) => {
    if (menuId === openMenu) {
      // setOpenMenu(null);
    } else {
      setOpenMenu(menuId);
    }
  };

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
            <h2 className="mt-0 mb-4 ta-l">
              가까운 펌킨 펫하우스에
            </h2>
            <h2 className="mt-0 mb-16 pb-64 ta-l has-bottom-divider">
              방문해 보세요!
            </h2>
          </div>


<div>

      <ButtonGroup>
        <Button className={openMenu===1? "button-primary button-sm" : "button-white button-sm"} onClick={() => handleMenuClick(1)}>
          청담점
        </Button>
        {/* <Button className={openMenu===2? "button-primary button-sm" : "button-white button-sm"} onClick={() => handleMenuClick(2)}>
          논현점
        </Button> */}
        <Button className={openMenu===3? "button-primary button-sm" : "button-white button-sm"} onClick={() => handleMenuClick(3)}>
          서울숲점
        </Button>
      </ButtonGroup>

      {openMenu === 1 && (
          <div data-reveal-value="20px">
                <Slider classname="mb-32" {...settings}>
                  <div>
                    <h3>          
                      <Image
                        className="has-shadow"
                        src={require('./../../assets/images/cd-1.png')}
                        alt="IoT Hero"
                        width={1080}
                        height={504} /></h3>
                  </div>
                  <div>
                    <h3>
                      <Image
                        className="has-shadow"
                        src={require('./../../assets/images/cd-2.png')}
                        alt="IoT Hero"
                        width={1080}
                        height={504} />
                    </h3>
                  </div>

                  <div>
                    <h3>
                    <Image
                        className="has-shadow"
                        src={require('./../../assets/images/cd-3.png')}
                        alt="IoT Hero"
                        width={1080}
                        height={504} />
                    </h3>
                  </div>
                  <div>
                    <h3>
                    <Image
                        className="has-shadow"
                        src={require('./../../assets/images/cd-4.png')}
                        alt="IoT Hero"
                        width={1080}
                        height={504} />
                    </h3>
                  </div>
                </Slider>
          </div>
      )}
      {openMenu === 1 && (
          <div data-reveal-value="20px">
            <div className="text-xxs fw-600 tt-u mt-12 mb-8 ta-l">
                펌킨 펫하우스 청담
            </div>
            <h3 className="mt-0 mb-12 ta-l">
              PUMPKIN Cheongdam
            </h3>
            <p className="mt-0 mb-12 ta-l text-xxs fw-600 text-color-warning">
            #유치원맛집 #단독주택 #야외잔디테라스 #친구들이많아요
            </p>
            <div className="text-xxs fw-600 tt-u pt-8 pb-8 ta-l has-top-divider">
                운영시간 매일 08:00 ~ 20:00
            </div>
            <div className="text-xxs fw-600 tt-u pt-8 pb-8 ta-l has-top-divider">
                주소 서울특별시 강남구 삼성로120길 36
            </div>     
            <div className="text-xxs fw-600 tt-u pt-8 pb-8 ta-l has-top-divider has-bottom-divider">
                문의 02-6204-5960
            </div>              
          </div>
      )}

      {/* {openMenu === 2 && (
          <div data-reveal-value="20px">
                <Slider classname="mb-32" {...settings}>
                  <div>
                    <h3>          
                      <Image
                        className="has-shadow"
                        src={require('./../../assets/images/ny-1.png')}
                        alt="IoT Hero"
                        width={1080}
                        height={504} /></h3>
                  </div>
                  <div>
                    <h3>
                      <Image
                        className="has-shadow"
                        src={require('./../../assets/images/ny-2.png')}
                        alt="IoT Hero"
                        width={1080}
                        height={504} />
                    </h3>
                  </div>

                  <div>
                    <h3>
                    <Image
                        className="has-shadow"
                        src={require('./../../assets/images/ny-3.png')}
                        alt="IoT Hero"
                        width={1080}
                        height={504} />
                    </h3>
                  </div>
                  <div>
                    <h3>
                    <Image
                        className="has-shadow"
                        src={require('./../../assets/images/ny-4.png')}
                        alt="IoT Hero"
                        width={1080}
                        height={504} />
                    </h3>
                  </div>
                </Slider>
          </div>
      )}
      {openMenu === 2 && (
          <div data-reveal-value="20px">
          <div className="text-xxs fw-600 tt-u mt-12 mb-8 ta-l">
              펌킨 펫하우스 논현
          </div>
          <h3 className="mt-0 mb-12 ta-l">
            PUMPKIN Nonhyeon
          </h3>
          <p className="mt-0 mb-12 ta-l text-xxs fw-600 text-color-warning">
          #편한주차 #옥상잔디테라스 #훈련맛집 #넓은호텔
            </p>
          <div className="text-xxs fw-600 tt-u pt-8 pb-8 ta-l has-top-divider">
              운영시간 매일 10:00 ~ 19:00
          </div>
          <div className="text-xxs fw-600 tt-u pt-8 pb-8 ta-l has-top-divider">
              주소 서울 강남구 봉은사로47길 59
          </div>     
          <div className="text-xxs fw-600 tt-u pt-8 pb-8 ta-l has-top-divider has-bottom-divider">
              문의 02-541-4939
          </div>              
        </div>
      )} */}

      {openMenu === 3 && (
          <div data-reveal-value="20px">
                <Slider classname="mb-32" {...settings}>
                  <div>
                    <h3>          
                      <Image
                        className="has-shadow"
                        src={require('./../../assets/images/sf-1.png')}
                        alt="IoT Hero"
                        width={1080}
                        height={504} /></h3>
                  </div>
                  <div>
                    <h3>
                      <Image
                        className="has-shadow"
                        src={require('./../../assets/images/sf-2.png')}
                        alt="IoT Hero"
                        width={1080}
                        height={504} />
                    </h3>
                  </div>

                  <div>
                    <h3>
                    <Image
                        className="has-shadow"
                        src={require('./../../assets/images/sf-3.png')}
                        alt="IoT Hero"
                        width={1080}
                        height={504} />
                    </h3>
                  </div>
                  <div>
                    <h3>
                    <Image
                        className="has-shadow"
                        src={require('./../../assets/images/sf-4.png')}
                        alt="IoT Hero"
                        width={1080}
                        height={504} />
                    </h3>
                  </div>
                </Slider>
          </div>
      )}
      {openMenu === 3 && (
          <div data-reveal-value="20px">
          <div className="text-xxs fw-600 tt-u mt-12 mb-8 ta-l">
              펌킨 펫하우스 서울숲점
          </div>
          <h3 className="mt-0 mb-12 ta-l">
            PUMPKIN Seoul Forest
          </h3>
          <p className="mt-0 mb-12 ta-l text-xxs fw-600 text-color-warning">
            #뚝섬역도보2분 #서울숲인근 #사진맛집 #커피맛집 #깔끔해요
            </p>
          <div className="text-xxs fw-600 tt-u pt-8 pb-8 ta-l has-top-divider">
              운영시간 주중 08:00 ~ 20:00 주말 10:00 ~ 20:00
          </div>
          <div className="text-xxs fw-600 tt-u pt-8 pb-8 ta-l has-top-divider">
              주소 서울 성동구 상원1길 22 1층
          </div>     
          <div className="text-xxs fw-600 tt-u pt-8 pb-8 ta-l has-top-divider has-bottom-divider">
              문의 02-994-4000
          </div>              
        </div>
      )}
  </div>
        </div>
      </div>
    </section>
  );
}

ContactHero.propTypes = propTypes;
ContactHero.defaultProps = defaultProps;

export default ContactHero;