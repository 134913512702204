import React from 'react';
// import sections
import FaqHero from '../components/sections/FaqHero';

const Faq = () => {

    return (
      <>
            <FaqHero className/>
      </>
    );
  }
  
  export default Faq;