import React from 'react';
// import sections
import ServiceHero from '../components/sections/ServiceHero';

const Service = () => {

    return (
      <>
            <ServiceHero className/>
      </>
    );
  }
  
  export default Service;