import React from 'react';
// import sections
import ContactHero from '../components/sections/ContactHero';

const Contact = () => {

    return (
      <>
            <ContactHero className/>
      </>
    );
  }
  
  export default Contact;